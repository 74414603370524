import { CommonModule }              from '@angular/common';
import { Component }                 from '@angular/core';
import {
    ActivatedRoute,
    Router 
}                                    from '@angular/router';

import { ElementInfoComponent }      from '../../element/element/element-info.component';
import {
    
    DeviceMonitoringRunningComponent,
    DeviceSurveyRunningComponent,
    DeviceTestRunningComponent
}                                    from '@Devices/device/elements/';
import { DateTimePipe }              from '@GuiElements/datetime.pipe';
import { TableDataComponent }        from '@GuiElements/table-data.component';

import { Device }                    from './device.class';

import { routeNames }                from '../../../app.routing.names';


@Component({
    selector:    'qp-device-info',
    templateUrl: 'device-info.component.html',
    styleUrls:   ['device-info.component.css'],
    imports:     [
        DateTimePipe,

        DeviceMonitoringRunningComponent,
        DeviceSurveyRunningComponent,
        DeviceTestRunningComponent,
        ElementInfoComponent,
        TableDataComponent,

        CommonModule
    ]
})
export class DeviceInfoComponent extends ElementInfoComponent
{
    constructor(ActRoute: ActivatedRoute,
                Router:   Router)
    {
        super(ActRoute, Router);
    }
    
    
    //
    // Getters
    //
    public get d(): Device
    {
        return this.data as Device;
    }


    public selectedFn(d: any): Promise<boolean> | undefined
    {
        // Now route to main org page
        return d instanceof Device && (d as Device).id
            ? this.Router.navigate(
                [ routeNames.devices, (d as Device).id ],
                { state: { parent: this.Router.url } }
            )
            : undefined;
    }
}