@if (d) {
    <qp-table-data
        [nullValues]=false
        [data]="
            [
                { k: 'Rsrp', v: d ? d + ' dBm' : '', c: class_ }
            ]
        "

        (selected)="select($event)"
    >
    </qp-table-data>
    <!-- <mat-icon class="tab-icon">{{ Icons.link }}</mat-icon> -->
}