import {
    Component,
    Input
}                        from '@angular/core';

import {
    ButtonBaseComponent,
    ButtonComponent
}                        from '@GuiElements/';
import { IconsMaterial } from '@Icons/';
import { Utils }         from '@Utils/';

// import { Test }          from './';
import {
    Test,
    TestResultCategories
}                        from '@Common/Elements/Tests/';


// Values here need to match associated CSS (or app style-colours.css)
enum TestCategoriesState {
    Great   = 'result-great',
    Good    = 'result-good',
    Average = 'result-average',
    Poor    = 'result-poor',
    Bad     = 'result-bad',
    None    = 'result-none'
}; // TestCategoriesState


@Component({
    selector:     'qp-test-icon',
    templateUrl:  'test-icon.component.html',
    styleUrls:   [
        'test-icon.component.css'
    ],
    imports:     [
        ButtonComponent
    ]
})
export class TestIconComponent extends ButtonBaseComponent
{
    private static readonly _icons: Record<string, string> = {
        [ Test.Types.dns ]:           IconsMaterial.test,
        [ Test.Types.icmp ]:          IconsMaterial.test,
        [ Test.Types.ftp ]:           IconsMaterial.test,
        [ Test.Types.sftp ]:          IconsMaterial.test,
        [ Test.Types.udp ]:           IconsMaterial.test,
        [ Test.Types.voice ]:         IconsMaterial.test,
        [ Test.Types.web ]:           IconsMaterial.test,
        [ Test.Types.youtube ]:       IconsMaterial.test,

        [ Test.Directions.downlink ]: IconsMaterial.test_downlink,
        [ Test.Directions.uplink ]:   IconsMaterial.test_uplink
    }; // _icons

    private static readonly _states: Map<String, string> = new Map<String, string>([
        [ TestResultCategories.Great.name,   TestCategoriesState.Great ],
        [ TestResultCategories.Good.name,    TestCategoriesState.Good ],
        [ TestResultCategories.Average.name, TestCategoriesState.Average ],
        [ TestResultCategories.Poor.name,    TestCategoriesState.Poor ],
        [ TestResultCategories.Bad.name,     TestCategoriesState.Bad ]
    ]); // _states

    private _icon:  string | undefined = "";
    private _name2: string = "";
    private _type:  string = "";


    @Input()
    public data:    Test | undefined;


    //
    // Getters and setters
    //
    @Input()
    public get icon(): string
    {
        if (this._icon) return this._icon;
        else {
            const i = TestIconComponent.getIconDirection(this.data);
            return (this._icon = i ? i : TestIconComponent.getIcon(this.data));
        }
    }

    public set icon(d)
    {
        this._icon = d;
    }


    @Input()
    public get name2(): string
    {
        return (this._name2)
            ? this._name2

            : (this._name2 = TestIconComponent.getName(
                this.data
            )); // getName
    }

    public set name2(d)
    {
        this._name2 = d;
    }


    @Input()
    public get type(): string
    {
        return (this._type)
            ? this._type

            : ((this.data instanceof Test)
                ? this.data.type
                : this._type
            );
    }

    public set type(d: string)
    {
        this._type = d;
    }


    //
    // Public methods
    //
    public getState(): string
    {
        return TestIconComponent.getState(this.data?.category);
    }


    //
    // Private methodss
    //
    private static getState(d: TestResultCategories | undefined): string
    {
        const i: string | undefined = (d instanceof TestResultCategories) ? TestIconComponent._states.get(d.name) : undefined;
        return (i) ? i : TestCategoriesState.None
    }

    private static getIcon(d: string | Test | undefined, e?: string): string
    {
        const i: string | undefined = TestIconComponent._icons[
            (d instanceof Test) ? d.type : d
        ];
        return (i) ? i : IconsMaterial.test;
    }


    private static getIconDirection(d: string | Test | undefined, e?: string): string | undefined
    {
        const t: string = ((d instanceof Test) ? ((d as any)?.direction ? (d as any).direction : "") : d);
        return TestIconComponent._icons[t ? t.toLocaleLowerCase() : t];
    }


    private static getName(d: string | Test | undefined, e?: string): string
    {
        return ((d instanceof Test) ? (d.type?.name ? d.type.name : d.type) : (d ? d : ""));
    }
}