const AppStrings_Common = {
    Common_Cancel:            "Cancel",
    Common_Close:             "Close",
    Common_Description:       "Description",
    Common_End:               "End",
    Common_Name:              "Name",
    Common_No:                "no",
    Common_Ok:                "OK",
    Common_Save:              "Save",
    Common_Start:             "Start",
    Common_Tbd:               "TBD",
    Common_Yes:               "yes",
}; // AppStrings_Common


export const AppStrings = {
    Common_Cancel:            AppStrings_Common.Common_Cancel,
    Common_Close:             AppStrings_Common.Common_Close,
    Common_No:                AppStrings_Common.Common_No,
    Common_Ok:                AppStrings_Common.Common_Ok,
    Common_Save:              AppStrings_Common.Common_Save,
    Common_Tbd:               AppStrings_Common.Common_Tbd,
    Common_Yes:               AppStrings_Common.Common_Yes,

    Device:                   "Device",
    Device_Actions:           "Actions",
    Device_Alert:             "Alert device",
    Device_Config:            "Re-send configuration",
    Device_Disconnect:        "Disconnect",
    Device_Live:              "Device Live",
    Device_LogDisable:        "Disable debug log",
    Device_LogEnable:         "Enable debug log",
    Device_LogDownload:       "Download debug log",
    Device_LogFileNamePrefix: "QuamtumPath Device Log",
    Device_Map:               "Device Map",
    Device_TestRunning:       "Test running", 
    Device_Upgrade:           "Upgrade device",

    Devices:                  "Devices",
    Devices_Online:           "Devices Online",
    Device_Id:                "Id",
    
    DeviceGroup:              "Device Group",
    DeviceGroup_Add:          "Add Device Group",
    DeviceGroup_Description:  AppStrings_Common.Common_Description,
    DeviceGroup_Name:         AppStrings_Common.Common_Name,
    DeviceGroup_Results:      "Results",
    DeviceGroup_RunTests:     "Run Tests",
    DeviceGroup_Start:        "Run",
    DeviceGroup_Stop:         "Stop",
    DeviceGroup_TestsStart:   "Run tests",
    DeviceGroup_TestsStop:    "Stop tests",
    DeviceGroup_ReconnectOnEachIteration: "Reconnect on each iteration",
    DeviceGroups:             "Device Groups",
    
    Image:                    "Image",
    Images:                   "Images",

    Live:                     "Live",

    Map:                      "Map",

    Monitoring_Running:       "Monitoring running",
    
    Survey:                   "Survey",
    Survey_GetReport:         "Download survey report",
    Survey_Report:            "Report",
    Survey_Running:           "Survey running",

    Surveys:                  "Surveys",

    SurveyPoints:             "Points",
    SurveyPoint:              "Point",
    SurveySightings:          "Sightings",
    SurveySighting:           "Sighting",

    Test:                     "Test",
    Test_Connection:          "Test Connection",
    Test_DeviceId:            "Device Id",
    Test_Direction:           "Direction",
    Test_Duration:            "Duration",
    Test_End:                 AppStrings_Common.Common_End,
    Test_Info:                "Info",
    Test_Profile:             "Test Profile",
    Test_Rate:                "Rate",
    Test_Result:              "Result",
    Test_RttAverage:          "Rtt Average",
    Test_Running:             "Test running",
    Test_Size:                "Size",
    Test_Start:               AppStrings_Common.Common_Start,
    Test_Type:                "Type",
}; // AppStrings