@if (d) {
    <qp-table-data
        [nullValues]="nullValues"
        [data]="
            [
                { k: 'Rnc Id',        v: d.rcid                        },
                { k: 'Local cell Id', v: d.lcid                        },
                { k: 'Psc',           v: d.psc                         },
                { k: 'Lac',           v: d.lac                         }
            ]">
    </qp-table-data>

    <qp-mobilecell-signalstrength
         [d]="d.signalstrength" [cat]="d.signalstrengthcategory"
    >
    </qp-mobilecell-signalstrength>

    <qp-mobilecell-signalquality
        [d]="d.signalquality" [cat]="d.signalqualitycategory"
    >
    </qp-mobilecell-signalquality>
}