<div class="parent" *ngIf="data">

<!-- <mat-card class="div" *ngIf="data"> -->
    <!-- <mat-card-content> -->

        <qp-device-info
            [data]=data
            [light]="light"
        >
        </qp-device-info>

        <div class="deviceinfo">
            <!-- @if (d.battery && d.battery.batteryLevel >= 0) {
                <qp-power-info
                    [light]="light"
                    [matCard]=true
                    [data]=d.battery
                >
                </qp-power-info>
            } -->
        
            <!-- @if (d.connection) {
                <qp-connection-info
                    [light]="light"
                    [matCard]=true
                    [data]=d.connection
                >
                </qp-connection-info>
            } -->
        </div>

        
        <!-- Need SIM data any more?  Now that most SIM data is no longer provided by Android due to security? -->
        <!-- <qp-sim-info [data]=s [light]="light" [simNum]="s.slot" *ngFor="let s of d.simsA"></qp-sim-info>
    
        <p></p> -->

            <!-- [gutterSize]="'0.5em'"> -->

            <!-- [rowHeight]="'fit'"> -->

        <!-- <mat-grid-list 
            [cols]="Media.isActive('lt-md') || mobilecellsArray().length <= 1 ? 1 : 2" 
            [gutterSize]="'0.5em'"
            [rowHeight]="'fit'"
        >
            <div *ngFor="let s of mobilecellsArray()">
                <mat-grid-tile>
                    <qp-mobile-cell-info
                        [light]="light" [matCard]=true [showMobileCellLevel]=false [cell]="s" >
                    </qp-mobile-cell-info>
                </mat-grid-tile>
            </div>
        </mat-grid-list> -->

        <div class="mobilecells2" flex>
            <div class="mobilecells" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="0.5em grid" fxLayoutAlign="center">
                <div fxFlex>
                    @for (s of mobilecellsArray; track s.cellid) {
                    <!-- <div *ngFor="let s of mobilecellsArray()" fxFlex> -->
                        <qp-mobile-cell-info
                            [light]="light"
                            [matCard]="true"
                            [useExpansion]="true"
                            [cell]="s"
                        >
                        </qp-mobile-cell-info>
                        <!-- [showMobileCellLevel]="false" -->

                    } <!-- for -->
                </div>
            </div>
        </div>
    
    <!-- </mat-card-content> -->
<!-- </mat-card> -->
</div>