<div class="content" *ngIf="d && d.technology">

    <!-- <mat-card-subtitle *ngIf="matCard">Mobile Cell: {{ d.technologyDisplayType | uppercase }}</mat-card-subtitle>
    <h4 *ngIf="! matCard" class="mat-subheading-1">Mobile Cell: {{ d.technologyDisplayType | uppercase }}</h4> -->

    <!-- <mat-card-subtitle *ngIf="matCard">Mobile Cell<mat-icon *ngIf="icon">{{ icon }}</mat-icon></mat-card-subtitle>
    <h4 *ngIf="! matCard" class="mat-subheading-1">Mobile Cell<mat-icon *ngIf="icon">{{ icon }}</mat-icon></h4> -->

    <!-- <div *ngIf="matCard; then useCard else noCard"></div> -->
    <ng-template [ngIf]="matCard" [ngIfThen]="useCard" [ngIfElse]="noCard"></ng-template>

    <!-- <mat-card-subtitle *ngIf="matCard">Mobile Cell<qp-mobilecell-technology-icon [technology]="technology"></qp-mobilecell-technology-icon></mat-card-subtitle>
    <h4 *ngIf="! matCard" class="mat-subheading-1">Mobile Cell<qp-mobilecell-technology-icon [technology]="technology"></qp-mobilecell-technology-icon></h4> -->

    <ng-template #useCard>
        @if (useExpansion) {
            <mat-expansion-panel
                [togglePosition]="'before'"
            >
                <!-- <div class="content2"> -->
                <mat-expansion-panel-header>
                    <ng-container *ngTemplateOutlet="Header"></ng-container>
                    <!-- <mat-panel-description>
                        <ng-container *ngTemplateOutlet="Summary"></ng-container>
                    </mat-panel-description> -->
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                    <ng-container *ngTemplateOutlet="Content"></ng-container>
                </ng-template>
                <!-- </div> -->
            </mat-expansion-panel>
        }
        @else {
            <mat-card>
                <div class="content2">
                    <mat-card-subtitle>
                        <ng-container *ngTemplateOutlet="Header"></ng-container>
                    </mat-card-subtitle>

                    <mat-card-content>
                        <ng-container *ngTemplateOutlet="Content"></ng-container>
                    </mat-card-content>
                </div>
            </mat-card>
        }
    </ng-template>


    <ng-template #noCard>
        <h4 class="mat-subheading-1">
            <ng-container *ngTemplateOutlet="Header"></ng-container>
        </h4>

        <ng-container *ngTemplateOutlet="Content"></ng-container>
    </ng-template>


    <ng-template #Header>
        <!-- Mobile Cell -->
         <div class="flex" fxFlex="auto">
            <div class="flex" fxLayout="row">
                @if (showMobileCellSim && d.simslot) {
                    <h4 class="padding">Sim {{ d.simslot }}</h4>
                }

                @if (showMobileCellTechnology) {
                    <qp-mobilecell-technology-icon class="icon" [technology]="technology" [technologydetail]="technologyDetail"></qp-mobilecell-technology-icon>
                }

                @if (showMobileCellLevel) {
                    <qp-mobilecell-level-icon      class="icon" [mobilecell]="d">                                               </qp-mobilecell-level-icon>
                }
            </div>
        </div>

        <!-- <qp-mobilecell-level-icon      *ngIf="showMobileCellLevel"      class="icon icon-1-5x" [mobilecell]="d">                                               </qp-mobilecell-level-icon> -->
    </ng-template>


    <ng-template #Summary>
            <qp-mobilecell-level-icon      class="icon" [mobilecell]="d">                                               </qp-mobilecell-level-icon>
    </ng-template>


    <!-- 'c' gives CSS classes for qp-table-data to apply using 'ngClass' -->
    <ng-template #Content>
        <qp-table-data [nullValues]="nullValues" [data]="
            [
                { k: 'Sim Slot',        v: d.simslot                                                                                   },
                d.operator !== d.plmn
                    ? { k: 'Operator',  v: d.operator | titlecase                                                                      }
                    : undefined,

                { k: 'Network',         v: d.plmn                                                                                      },
                { k: 'Technology',      v: d.technologystr, c: d.technologyType                                                        },

                d.frequencyband
                    ? { k: 'Frequency Band', v: d.frequencyband + (d.frequencybandname ? ' (' + d.frequencybandname + ')' : '') | lowercase }
                    : undefined,

                { k: 'Dl Arfcn',        v: d.dlarfcn                                                                                   },
                { k: 'Dl Frequency',         v: d.dlfrequency ? d.dlfrequency + ' MHz' : ''                                                 },

                d.ularfcn
                    ? { k: 'Ul Arfcn',  v: d.ularfcn                                                                                   }
                    : undefined,

                d.ulfrequency
                    ? { k: 'Ul Freq',   v: d.ulfrequency ? d.ulfrequency + ' MHz' : ''                                                 }
                    : undefined,

                { k: 'Cell Id',         v: d.cellid                                                                                    },
                { k: 'Rssi',            v: d.rssi ? d.rssi + ' dBm' : ''                                                               }
            ]">
        </qp-table-data>
        <!-- { k: 'Technology',               v: d.technologyType + (d.technologydetail && d.technologyType !== d.technologydetail ? ' (' + d.technologydetail + ')' : ''), c: d.technologyType }, -->

        <!-- <div [ngSwitch]="d.technology"> -->
        @switch (d.technology) {
            @case (mobileTechnology.Cdma)     { <qp-mobile-cell-cdma-info    [light]="light" [cell]="d" [nullValues]="nullValues"></qp-mobile-cell-cdma-info> }
            @case (mobileTechnology.Gsm)      { <qp-mobile-cell-gsm-info     [light]="light" [cell]="d" [nullValues]="nullValues"></qp-mobile-cell-gsm-info> }
            @case (mobileTechnology.Lte)      { <qp-mobile-cell-lte-info     [light]="light" [cell]="d" [nullValues]="nullValues"></qp-mobile-cell-lte-info> }
            @case (mobileTechnology.Nr)       { <qp-mobile-cell-nr-info      [light]="light" [cell]="d" [nullValues]="nullValues"></qp-mobile-cell-nr-info> }
            @case (mobileTechnology.Nr_nsa)   { <qp-mobile-cell-nrnsa-info   [light]="light" [cell]="d" [nullValues]="nullValues"></qp-mobile-cell-nrnsa-info> }
            @case (mobileTechnology.Td_scdma) { <qp-mobile-cell-tdscdma-info [light]="light" [cell]="d" [nullValues]="nullValues"></qp-mobile-cell-tdscdma-info> }
            @case (mobileTechnology.Umts)     { <qp-mobile-cell-umts-info    [light]="light" [cell]="d" [nullValues]="nullValues"></qp-mobile-cell-umts-info> }
        } <!-- switch -->
        <!-- </div> -->
    </ng-template>
        
</div> <!-- d.technology -->



<!-- <div class="div" *ngIf="d && d.technology">

    <p></p>

    <h4 class="mat-subheading-1">Mobile Cell</h4>
    <mat-list dense>
        
        <qp-data-element-item *ngIf="d.simslot > 0"   [light]="light" [key]="'Sim slot'"           [text]="d.simslot">      </qp-data-element-item>
        <qp-data-element-item                         [light]="light" [key]="'Network'"            
            [text]="d.operator ? d.operator + (d.plmn ? ' (' + d.plmn + ')' : '') : (d. plmn ? d.plmn : '')">                                                                                 </qp-data-element-item>
        <qp-data-element-item                         [light]="light" [key]="'Network technology'"
            [text]="d.technology + (d.technologydetail && d.technologydetail !== d.technology ? ' (' + d.technologydetail + ')' : '')">                            </qp-data-element-item>
        <qp-data-element-item *ngIf="d.frequencyband" [light]="light" [key]="'Frequency band'"
            [text]="d.frequencyband + (d.frequencybandname ? ' (' + d.frequencybandname + ')' : '')">                       </qp-data-element-item>
        <qp-data-element-item *ngIf="d.dlarfcn > 0"   [light]="light" [key]="'Dl Arfcn'"           [text]="d.dlarfcn">      </qp-data-element-item>
        <qp-data-element-item                         [light]="light" [key]="'Cell Id'"            [text]="d.cellid">       </qp-data-element-item>
        <qp-data-element-item                         [light]="light" [key]="'Rssi'"               [text]="d.rssi">         </qp-data-element-item>
    </mat-list>

    <div [ngSwitch]="d.technology">
        <qp-mobile-cell-nr-info      *ngSwitchCase="mobileTechnology.nr"      [light]="light" [cell]="d"></qp-mobile-cell-nr-info>
        <qp-mobile-cell-lte-info     *ngSwitchCase="mobileTechnology.lte"     [light]="light" [cell]="d"></qp-mobile-cell-lte-info>
        <qp-mobile-cell-umts-info    *ngSwitchCase="mobileTechnology.umts"    [light]="light" [cell]="d"></qp-mobile-cell-umts-info>
        <qp-mobile-cell-gsm-info     *ngSwitchCase="mobileTechnology.gsm"     [light]="light" [cell]="d"></qp-mobile-cell-gsm-info>
        <qp-mobile-cell-cdma-info    *ngSwitchCase="mobileTechnology.cdma"    [light]="light" [cell]="d"></qp-mobile-cell-cdma-info>
        <qp-mobile-cell-tdscdma-info *ngSwitchCase="mobileTechnology.tdscdma" [light]="light" [cell]="d"></qp-mobile-cell-tdscdma-info>
    </div>
</div> -->


<!--
if      (this.frequencyName) {
    contentStr += this.frequencyBand + " (" +  this.frequencyNominal + " MHz - " +  this.frequencyName + ")";
}
else if (this.frequencyBand > 0) {
   const bandInfo: string = MobileNetworkBandService.getBandInfo(this.technology, this.frequencyBand);
   contentStr += this.frequencyBand + (bandInfo ? " (" + bandInfo + ")" : "");
}
else {
    contentStr += "N/A";
}
-->