import {
    Injectable,
    Injector
}                        from '@angular/core';
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef
 }                       from '@angular/material/dialog';

import { BaseService }   from '@Base/';

import {
    DialogComponent,
    DialogComponentDataI
}                        from '../Components/dialog.component';


export { MatDialogRef }  from '@angular/material/dialog';


@Injectable({
    providedIn: 'root'
})
export class DialogService extends BaseService
{
    private                 mDialogRefL:          any    = undefined; // leave as 'any'; closeAll() doesn't exist on MatDialogRef
    private static readonly mDialogHeightDefault: number = 50;
    private static readonly mDialogWidthDefault:  number = 50;
    private static readonly mDialogHeightMin:     string = "20em";
    private static readonly mDialogWidthMin:      string = "20em";


    constructor(private readonly MatDialog: MatDialog)
    {
        super();
    }  


    static getDialogInstance(d: MatDialogRef<any> | undefined): any | undefined
    {
        return d?.componentInstance;
    }


    static getInstance(d: MatDialogRef<any> | undefined): any | undefined
    {
        return DialogService.getDialogInstance(d)?.compRef?.instance;
    }


    //
    // Public methods
    //
    public showDialog(component:    any,
                      height?:      number | string,
                      width?:       number | string,
                      data?:        any,
                      disableClose: boolean = false,
                      enableOk:     boolean = false,
                      transparent:  boolean = false,
                      injector?:    Injector): MatDialogRef<any> | undefined
    {
        if (! component) return undefined;

        const lData: DialogComponentDataI = {
            component:          component,
            // disableCloseButton: disableClose,
            enableOkButton:     enableOk,
            injector:           injector
        }; // lData

        if (! (component instanceof DialogComponent)) {
            lData.component = component;
            component = DialogComponent;
        }

        if (data) lData.data = data;

        const dialogConfig: MatDialogConfig = {
            autoFocus:         true,
            closeOnNavigation: true, // default
            data:              lData, // pass data to dialog
            disableClose:      disableClose, // prevents closure by clicking outside of dialog
            hasBackdrop:       ! transparent,
            height:            (typeof height === "string") ? height : ((height != null) ? height + '%' : 'auto'),
            minHeight:         DialogService.mDialogHeightMin,
            minWidth:          DialogService.mDialogWidthMin,
            panelClass:        [ transparent ? 'dialog-transparent' : 'dialog' ],
            restoreFocus:      true,
            width:             (typeof width === "string") ? width : ((width != null) ? height + '%' : 'auto')
        }; // dialogConfig

        // console.debug(dialogConfig);

        return (this.mDialogRefL = 
            (this.MatDialog?.open instanceof Function)
                ? this.MatDialog.open(component, dialogConfig)
                : this.mDialogRefL
        ); // return
    }


    public showDialog2(component: any, data? : any): MatDialogRef<any> | undefined
    {
        return this.showDialog(
            component,
            DialogService.mDialogHeightDefault,
            DialogService.mDialogWidthDefault,
            data
        ); // return
    }


    //
    // Protected methods
    //
    
    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();

        if (this.mDialogRefL?.closeAll instanceof Function) this.mDialogRefL.closeAll();
        this.mDialogRefL = undefined;
    }
}