import {
    Component,
    Input
}                                     from '@angular/core';

import { ElementInfoComponent }       from '../../element/element/element-info.component';
import { TableDataComponent }         from '@GuiElements/table-data.component';
import {
    MobileCellSignalQualityComponent,
    MobileCellSignalStrengthComponent
}                                     from '@MobileCells/MobileCell/elements/';

import { MobileCellCdmaCommon }       from './';


@Component({
    selector:     'qp-mobile-cell-cdma-info',
    templateUrl:  'mobile-cell-cdma-info.component.html',
    styleUrls:   [
        'mobile-cell-cdma-info.component.css'
    ],
    imports:     [
        // MobileCellSignalQualityComponent,
        // MobileCellSignalStrengthComponent,
        TableDataComponent
    ]
})
export class MobileCellCdmaInfoComponent extends ElementInfoComponent
{
    @Input()
    public cell:       MobileCellCdmaCommon;

    @Input()
    public nullValues: boolean               = true;


    //
    // Getters
    //
    get d(): any
    {
        return this.cell;
    }
}