import {
    BehaviorSubject,
    Observable
}                           from 'rxjs';

import { 
    Icons,
    IconsService
}                           from '@Icons/';
import { Globals }          from '@Utils/';

import {
    Element,
    ElementState,
    ElementStatus
}                           from '../../element/';
import { MobileCell }       from '../../mobile-cells/';
import { Notification }     from '../../notification/';
import { Device }           from '../device/';
import { MobileCellCommon } from '@Common/Elements/MobileCell/';

// Special case to allow common-defined class to be accessible thoughout web app
// but only actually imported in one place, here

import {
    DeviceRepeaterCommon,
    DeviceRepeaterTypes
}                             from './';


export enum DeviceRepeaterNotificationSeverity
{
    low    = 'low',
    medium = 'medium',
    high   = 'high',
    none   = 'none',
}


export class DeviceRepeater extends Device
{
    private static          msPerDay:          number                     = Globals.msPerDay;

    // public  static readonly go:                string                     = "go";
    // public  static readonly quatra:            string                     = "quatra";
    // public  static readonly solo:              string                     = "solo";

    private                 _notifSeverityMax: any                        = Notification.NotificationSeverity.none
    //DeviceRepeaterNotificationSeverity.none;
    //NotificationCommon.NotificationSeverity.none
    
                                                      //= DeviceRepeaterCommon.NotificationSeverity.none;
    // private                 _cells:            Record<string, MobileCell> = {};
    // private                 _cellsV:           MobileCellCommon[]         = [];
    //private                 _sims:             object                     = {}; // used to store operator(s)

    // Override
    private               _update$:          BehaviorSubject<DeviceRepeater>;


    public constructor(d?: any, iconsService?: IconsService)
    { 
        super(d, iconsService);

        this.merge(d);

        this._update$ = new BehaviorSubject<DeviceRepeater>(this);
    }


    // Factory
    public static get(d: any, iconsService?: IconsService)
    {
        return new DeviceRepeater(d, iconsService);
    }


    //
    // Getters and setters
    //
    public get antenna(): string
    {
        return this.elementC instanceof DeviceRepeaterCommon
            ? (this.elementC as DeviceRepeaterCommon).antenna
            : "";
    }


    public get cusnum(): number
    {
        return this.elementC instanceof DeviceRepeaterCommon
            ? (this.elementC as DeviceRepeaterCommon).cusnum
            : 0;
    }

    
    // Override
    public override get iconStr(): string
    {
        if (this.model) {
            let icon: string = Icons.nextivityQuatra; // [TBD]

            if      (this.model.startsWith(DeviceRepeaterTypes.quatra)) icon = Icons.nextivityQuatra;
            else if (this.model.startsWith(DeviceRepeaterTypes.solo))   icon = Icons.nextivitySolo;
            else if (this.model.startsWith(DeviceRepeaterTypes.go))     icon = Icons.nextivityGo;

            return icon
        }

        return "";
    }


    public get mobilecells(): Record<string, MobileCellCommon> | undefined
    {
        return this.elementC instanceof DeviceRepeaterCommon
            ? (this.elementC as DeviceRepeaterCommon).mobilecells
            : undefined;
    }

    public get mobilecellsV(): MobileCellCommon[]
    {
        return this.mobilecells && Array.isArray(Object.values(this.mobilecells))
            ? Object.values(this.mobilecells).sort((a, b) =>
                   a.plmn              ? a.plmn.localeCompare(b.plmn)                           : 0
                || b.technologydisplay ? b.technologydisplay.localeCompare(a.technologydisplay) : 0
                || a.cellid - b.cellid
            ) // sort

            : [];
    }


    public get notification(): Observable<DeviceRepeater>
    {
        return this._update$.asObservable();
    }

    // Override
    protected override get notificationO(): object | Notification | undefined
    {
        return undefined;
    }

    // Override
    protected override set notificationO(d: object | Notification | undefined)
    {
        super.notificationO = d;

        // Now find max alarm severity
        if (this.notificationsV.length <= 0) this.notifSeverityMax = DeviceRepeaterNotificationSeverity.none
        else {
            let state: DeviceRepeaterNotificationSeverity = DeviceRepeaterNotificationSeverity.low;
            for (const d of this.notificationsV) {
                if (d) {
                    if ((state = d.severity as DeviceRepeaterNotificationSeverity)
                        === DeviceRepeaterNotificationSeverity.high) break;
                    // state = d.severity as DeviceRepeaterNotificationSeverity;
                    // if (state === DeviceRepeaterNotificationSeverity.high) break;
                }
            } // for
            this.notifSeverityMax = state;
        }
    }


    private get notifSeverityMax(): DeviceRepeaterNotificationSeverity
    {
        return this._notifSeverityMax;
    }

    private set notifSeverityMax(d: DeviceRepeaterNotificationSeverity)
    {
        // this.state = this.setState(this._notifSeverityMax = d);
        this.notify(undefined);
    }


    public get operator(): string
    {
        return this.elementC instanceof DeviceRepeaterCommon
            ? (this.elementC as DeviceRepeaterCommon).operator
            : "";
    }


    public get sku(): string
    {
        return this.elementC instanceof DeviceRepeaterCommon
            ? (this.elementC as DeviceRepeaterCommon).sku
            : "";
    }


    protected override get update$(): BehaviorSubject<any>
    {
        return this._update$;
    }


    // Setter configured like this protect setting
    // private set cellsI(d: object)
    // {
    //     if (d) {
    //         Object.entries(d).forEach(([k, v]): void => {
    //             if (k && v) {
    //                 const n: MobileCell = v ? MobileCellHelper.mergeOrGet(undefined, v) : undefined;
    //                 if (n) this._cells[k] = n;
    //             }
    //         }); // forEach
    //     }
    //     else {
    //         // Clear map
    //         Object.keys(this.cells).forEach((e: string): void => {
    //             if (e) delete this.cells[e];
    //         }); // forEach
    //     }

    //     this._cellsV = Object.values(this.cells);
    // }




    // Override
    // protected set statusI(d: ElementStatus)
    // {
    //     super.statusI = d;

    //     this.state = this.setState();
    // }


    //
    // Public methods
    //

    // Override
    public override merge(d: any, doNotify: boolean = true): Element
    {
        super.merge(d);

        // this.state = this.setState();
        
    //     if (d) {
    //         // this.cellsI   = this.getAttr(d, 'cells');
    //         // this.operator = this.getAttr(d, 'operator');
    //         // this.sku      = this.getAttr(d, 'sku');

    //         // [TBD] needed?  Or can be removed once Nextivity script provides this status?
    //         // if (! d.status) {
    //         //     const deviceUpdatedDate: Date = this.lastUpdatedDate;// ? new Date(d.operationaldate) : null;
    //         //     const systemUpdatedDate: Date = d.updated ? new Date(d.updated) : null;

    //         //     this.status = (deviceUpdatedDate && systemUpdatedDate
    //         //         && (Math.floor(systemUpdatedDate.getTime() - deviceUpdatedDate.getTime()) < Globals.msPerDay) )
    //         //         ? DeviceStatus.online
    //         //         : DeviceStatus.offline;
    //         // }

    //         //this.sim = d; // [TBD]

    //         if (doNotify) this.notify();
    //     }

        return this;
    }


    //
    // Protected methods
    //
    
    // Override
    protected override getIcon(cb: (d: any) => void): void 
    {
        return super.getIcon((d2: any) => {
            if (d2) {
                if(cb instanceof Function) return cb(d2);
            }
            else {
                // No icon
                if(cb instanceof Function) return cb(this.iconStr);
            }
        }); // getIcon()
    }


    // Override
    protected override notify(d: any): boolean
    {
        return super.notify(d, false);
    }


    //
    // Private methods
    //
    private setState(d: DeviceRepeaterNotificationSeverity = this.notifSeverityMax): ElementState
    {
        let e: ElementState = this.state;

        if (this.statusI === ElementStatus.Offline) {
            e = ElementState.None;
        }
        else {
            // Update state icon based on notifications
            switch (d) {
                case DeviceRepeaterNotificationSeverity.high:
                    e = ElementState.Poor
                    break;

                case DeviceRepeaterNotificationSeverity.medium:
                case DeviceRepeaterNotificationSeverity.low:
                    e = ElementState.Average;
                    break;

                case DeviceRepeaterNotificationSeverity.none:
                default:
                    e = ElementState.Good
            };
        }

        return e;
    }
}