<!-- @if (data) { -->
    <qp-button
        [class]="getState()"
        [class_]="getState()"
        [enabled]="enabled"
        [icon]="icon"
        [label]="AppStrings.Test + ': ' + name2"
        [name]="name2"
        
        (clickL)="onClick($event)" 
    >
    </qp-button>
<!-- } -->