import {
    BehaviorSubject,
    Observable,
    Subject
}                              from 'rxjs';

import { Base }                from '@Base/';

import { IconsService }        from '@Icons/';
// import { MapElementLatLng }    from '@Map/map-elements/map-element-latlng/';
import { Dateq }               from '@Utils/';

import { ElementCommon }       from '@Common/Elements/Element.class';
import { ElementMainCommon }   from '@Common/Elements/ElementMain.class';
import { ElementState }        from '@Common/Elements/ElementState.class';

import { ElementHelperCommon } from '@Common/Elements/Element-helper.class';
import { GeolocationCommon }   from '@Common/Elements/Devices/Attributes/';
 

// Replaced by that in Common
// export enum ElementStateT {
//     good    = 'good',
//     average = 'average',
//     poor    = 'poor',
//     none    = 'none',
//     unknown = 'unknown'
// };


export abstract class Element extends Base
{
    protected static          _tag:                 string           = "element"; // used in URLs

    protected static readonly _iconStr:             string           = "";

    private                   _elementC:            ElementCommon;//    = undefined; // wrapper for common object
    private                   _geolocation:         GeolocationCommon | undefined;
    private                   _icon:                object;
    private                   _lastUpdatedDate:     Dateq;
    private                   _location:            string;
    private                   _marker:              any;//MapElementMarker = undefined;
    // private                   _state:               ElementState     = ElementState.None;

    private                   _geolocationChanged$: Subject<Element>;


    // Need to be overridden by specialisations
    // protected abstract _update$:                      BehaviorSubject<any>;
    protected abstract get update$():                   BehaviorSubject<any>;
    public    abstract get notification():            Observable<any>;
    protected abstract getIcon(cb: (d: any) => void): void 


    protected constructor(                   d?:            any,
                          protected readonly IconsService?: IconsService,
                                             type?:         any)
    { 
        super();

        this._geolocationChanged$ = new Subject<Element>();
        this.elementC = (d instanceof ElementCommon) ? d : (type ? ElementHelperCommon.get(d, type) : undefined);
    }


    //
    // Getters and setters
    //
    public get description(): string
    {
        return this.elementC ? this.elementC.description : "";
    }


    // protected
    public get elementC(): any // [TBD] - type
    {
        return this._elementC;
    }

    // protected
    public set elementC(d: any) // [TBD] - type
    {
        this._elementC = d;
    }


    public get geolocation(): GeolocationCommon | undefined
    {
        return (this._geolocation instanceof GeolocationCommon)
            ? this._geolocation
            : (this._geolocation = (
                this.geolocationC instanceof GeolocationCommon
                && this.geolocationC.isValid instanceof Function
                && this.geolocationC.isValid())
                    ? this.geolocationC
                    : undefined
             );

            // return this._geolocation instanceof MapElementLatLng
            // ? this._geolocation
            // : (this._geolocation = (
            //         this.geolocationC instanceof GeolocationCommon
            //         && this.geolocationC.isValid instanceof Function
            //         && this.geolocationC.isValid()
            //             ? MapElementLatLng.get2(this.geolocationC)
            //             : undefined
            //         )
            // );

        //     ? MapElementLatLng.get(
        //         this.geolocationC.latitude,
        //         this.geolocationC.longitude,
        //         this.geolocationC.noWrap,
        //         this.geolocationC.accuracy,
        //         this.geolocationC.speed,
        //       )
        //    : undefined;
    }

    private get geolocationC(): GeolocationCommon | undefined
    {
        return (this.elementC instanceof ElementMainCommon)
            ? this.elementC.geolocation
            : undefined;
    }

    // public get geolocationStr(): string
    // {
    //     return this.geolocation ? this.geolocation.locationStr : "";
    // }

    public get geolocationChanged(): Observable<Element>
    {
        return this._geolocationChanged$.asObservable();
    }

    
    public get icon(): object
    {
        return this._icon;
    }

    public set icon(d: object)
    {
        this._icon = d;

        // Do NOT call notify() here as this sets icon(), recursion!
        //this.setAttr('_icon', d);
    }

    public get iconStr(): string
    {
        return Element._iconStr;
    }


    // Override
    public get id(): string
    {
        return this.elementC instanceof ElementCommon
            ? (this.elementC as ElementCommon).id
            : "";
    }

    // Override
    public set id(d: string)
    {
        // Do nothing
    }


    public get lastUpdatedDate(): Dateq | undefined
    {
        return this.elementC instanceof ElementCommon
            ? (this.elementC as ElementCommon).lastUpdatedDate
            : undefined;
    }

    public get lastUpdatedDateStr(): string
    {
        return this.elementC instanceof ElementCommon
            ? (this.elementC as ElementCommon).lastUpdatedDateStr
            : "";
    }


    public get location(): string
    {
        return this._location;
    }

    public set location(d: string)
    {
        this._location = d;
    }


    public get marker(): any// MapElementMarker
    {
        return this._marker;
    }

    public set marker(d: any)//MapElementMarker)
    {
        this.setAttr('_marker', d);
    }


    public get markerTitle(): string
    {
        return this.description ? this.description : this.id;

        // return (
        //     this.id
        //     + ((this.description && this.description != this.id) ? ", " + this.description : "")
            // + (this.geolocation
            //     ? (  ", Lat: "    + this.geolocation.latitudeStr;
            //        + ", Long: "   + this.geolocation.longitudeStr;
            //     : "")
        // );
    }

    
    public get state(): ElementState
    {
        return this.elementC instanceof ElementMainCommon
            ? (this.elementC as ElementMainCommon).state
            : ElementState.Unknown;
    }

    // public set state(d: ElementState)
    // {
    //     this._state = d;
    // }


    // Needed by *-info.components
    public get tag(): string
    {
        return Element.tag;
    }

    
    //
    // Static methods
    //
    public static get tag(): string
    {
        return this._tag;
    }

    public static set tag(d: string)
    {
        this._tag = d;
    }


    //
    // Public methods
    //

    // Override
    public override cleanUp(): void
    {
        super.cleanUp();

        if (this._geolocationChanged$ instanceof Subject) this._geolocationChanged$.complete();
        if (this.update$              instanceof Subject) this.update$.complete();
    }


    public infoWinContent(): string
    {
        let contentStr: string = 
              "<div id=\"map-infowin\" style=\"color: black;\">"
            + "<p>"

        contentStr += "<b>Last updated</b>: "    + this.lastUpdatedDateStr;

        contentStr += "<br><br><b>Id</b>: "      + this.id;
        
        contentStr += "<br><b>Description</b>: " + this.description;


        // Geolocation
        // if (this.geolocation && this.geolocation.latitudeStr && this.geolocation.longitudeStr) {
        //     contentStr +=
        //           "<br><br><b><b><i>Location</i></b></b>"
        //         + "<br><b>Latitude</b>: "  + this.geolocation.latitudeStr  + " deg"
        //         + "<br><b>Longitude</b>: " + this.geolocation.longitudeStr + " deg";

        //         if (this.geolocation.accuracyStr) {
        //             contentStr += "<br><b>Accuracy</b>: " + this.geolocation.accuracyStr + " m";
        //         }

        //         if (this.geolocation.speedStr) {
        //             contentStr += "<br><b>Speed</b>: "    + this.geolocation.speedKphStr + " Kph"; // convert from m/s
        //         }
        // }
        // else {
        //     contentStr +=
        //         "<br><b>Latitude</b>: "  + "N/A" +        
        //         "<br><b>Longitude</b>: " + "N/A";
        // }

        return contentStr;
    }


    public merge(d: object, doNotify: boolean = true): Element
    {
        if (d) {
            if (this.elementC) this.elementC._merge(d instanceof Element && d.elementC ? d.elementC : d);

            // this.id                         = this.getAttr(d, 'id');
            // if (! this.id) this.id          = this.getAttr(d, 'serialNum');

            // this.setAttr2('description',      this.getAttr(d, 'description'));
            // If description is the same as the ID, override with comment (if there is one)
            // if (! this.description || this.description == this.id) this.setAttr2('description', this.getAttr(d, 'comment'));
            
            this.setAttr2('icon',             this.getAttr(d, 'icon'));
            this.setAttr2('lastUpdatedDateI', this.getAttr(d, 'lastUpdatedDate')); // setter will create date object
            this.setAttr2('location',         this.getAttr(d, 'location'));

            // if (d.description     != null) this.description      = this.getAttr(d, 'description';
            // if (d.icon            != null) this.icon             = d.icon;
            
            // if      (d.id         != null) this.id               = d.id;
            // else if (d.serialNum  != null) this.id               = d.serialNum;

            // if (d.lastUpdatedDate != null) this.lastUpdatedDateI = d.lastUpdatedDate; // setter will create date object
            // if (d.location        != null) this.location         = d.location;

            //Object.assign(this, data);
            // this.setGeolocation(MapElementLatLng.get(
            //     this.getAttr(d, 'latitude'),
            //     this.getAttr(d, 'longitude'),
            //     this.getAttr(d, 'noWrap'),
            //     this.getAttr(d, 'accuracy'),
            //     this.getAttr(d, 'speed'),
            // ));

            // Update icon if necessary
            //if (this.iconFn) this.icon = this.iconFn.apply(this);
            // if (data.latitude != null && data.longitude != null) {
            //     const geolocation: MapElementLatLng = MapElementLatLng.get(data);
            //     if (! data.geolocation || data.accuracy == null || data.accuracy <= MapElementLatLng.locationAccMax) {
            //         if (geolocation) this.geolocation = geolocation;
            //     }
            //     else {
            //         console.log("Not updating element '" + this.id + "'; "
            //             + "location position accuracy (" + data.accuracy + " m) is > limit (" + MapElementLatLng.locationAccMax + "m)");
            //     }
            // }
        }

        if (doNotify) this.notify();
        
        return this;
    }


    //
    // Protected methods
    //
    protected getAttr(d: any, a: string): any
    {
        // Check both attr (normal QP) and _attr (can be created by server)
        return (d && a)
            ? (a in d
                ? d[a]
                : ( ('_' + a) in d ? d['_' + a] : undefined)
            )
                
            : undefined;
    }

    // // Specialisations should overload and then call callback fn
    // protected getIcon(cb: (d: any) => void): void 
    // {
    //     console.log("ERROR: Classes need to override this method: getIcon()");

    //     if (this.IconsService) this.IconsService.getIcon(undefined, undefined, (d: any): void => {
    //         if (cb) cb(d);
    //     });
    // }


    protected notify(d?: any, date: boolean = true): boolean
    {
        // if (date) this.lastUpdatedDateI = Dateq.get();
        this.updateIcon((d: any): void => {
            if (this.update$ instanceof Subject) this.update$.next(this);
            // if (this.update$ instanceof Subject) this.update$.next(this);
        }); // [TBD]

        return true;
    }


    protected setAttr(a: string, d: any): boolean
    {
        // console.log(a);
        // console.log(d);
        // console.log(typeof d);
        // console.log(this[a]);
        // console.log(a in this)
        // console.log(typeof d !== 'undefined')
        if (typeof d !== 'undefined') {
// if ((a in this) && (typeof d !== 'undefined')) {
 //           console.log("here2");
            this.notify(this[a as keyof typeof this] = d)
        }
        else {
  //          console.log("here3");
        }
        //return ((a in this) && (typeof d !== 'undefined')) ? this.notify(this[a] = d) : false;
  //      console.log(this[a]);

        return true;
    }


    protected setAttr2(a: string | undefined, d: any): boolean
    {
        return a && (typeof d !== 'undefined') && (! Number.isNaN(d)) && (this[a as keyof typeof this] = d);
    }


    protected updateIcon(cb: (d: any) => void): void
    {
        this.getIcon((d: any): void => { // calls child element
            this.icon = d; // do separately in case callback is undefined
            if (cb instanceof Function) cb(this.icon);
        }); // getIcon
    }


    //
    // Private methods
    //
    // protected setGeolocation(d: MapElementLatLng): MapElementLatLng | undefined
    // {
    //     if (d instanceof MapElementLatLng) {
    //         if      (! (this.geolocation instanceof MapElementLatLng)) {
    //             // this.geolocation = d; // store initial location whatever the accuracy so that icon appears on map
    //         }
    //         else if (d.accuracy <= MapElementLatLng.locationAccMax) {
    //             // Check if location changed; emit event if it has
    //             if (d !== this.geolocation && this._geolocationChanged$ instanceof Subject) this._geolocationChanged$.next(this);
    //             // return (this.geolocation = d);
    //         }
    //         else {
    //             console.log("Not updating element '" + this.id + "'; "
    //                 + "location position accuracy (" + d.accuracy + " m) is > limit (" + MapElementLatLng.locationAccMax + "m)");
    //         }
    //     }

    //     return undefined;
    // }
}