import { CommonModule }          from '@angular/common';
import {
    Component,
    Input
}                                from '@angular/core';

import { ElementInfoComponent }  from '../../element/element/element-info.component';
import { DeviceIdComponent }     from '@Devices/device/elements/';
import { DateTimePipe }          from '@GuiElements/datetime.pipe';
import {
    TableDataComponent,
    TableDataValue,
    isTableDataValue
}                                from '@GuiElements/table-data.component';
import { MaterialCustomModule }  from '@Material/';

import { TestFtpInfoComponent }  from '../test-ftp/';
import { TestIcmpInfoComponent } from '../test-icmp/';

import { TestIconComponent }     from './test-icon.component';

import { routeNames }            from '../../../app.routing.names';

import {
    Test,
    // TestTypes
}                                from '../';


@Component({
    selector:    'qp-test-info',
    templateUrl: 'test-info.component.html',
    styleUrls:   [
        'test-info.component.css'
    ],
    imports:     [
        CommonModule,

        DateTimePipe,
        DeviceIdComponent,
        TableDataComponent,
        MaterialCustomModule,

        TestFtpInfoComponent,
        TestIcmpInfoComponent,

        TestIconComponent
    ]
})
export class TestInfoComponent extends ElementInfoComponent
{
    @Input()
    public cell:                     any;


    @Input()
    public nullValues:               boolean = true;
    

    @Input()
    public showMobileCellTechnology: boolean = true;


    @Input()
    public showMobileCellLevel:      boolean = true;


    @Input()
    public useExpansion:             boolean = false;


    //
    // Getters
    //
    get d(): Test
    {
        return this.cell;
    }

    get TestTypes(): typeof Test.Types
    {
        return Test.Types;
    }

    
    get type(): string
    {
        return this.d?.type;
    }


    //
    // Public methods
    //
    public selectedFn(d2: any): Promise<boolean> | undefined
    {
        if (isTableDataValue(d2) && (d2 as TableDataValue).v) {
            // Route to device
            return this.Router.navigate(
                [ routeNames.devices, (d2 as TableDataValue).v ],
                { 
                    state: { parent: this.Router.url } 
                }
            ); // navigate()
        }
        else {
            this.select(d2);
            return undefined;
        }
    }
}