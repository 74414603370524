import { CommonModule }              from '@angular/common';
import { Component }                 from '@angular/core';
import { MediaObserver }             from '@angular/flex-layout';
import {
    ActivatedRoute,
    Router 
}                                    from '@angular/router';

import { ElementInfoComponent }      from '../../element/element/element-info.component';
import { TableDataComponent }        from '@GuiElements/table-data.component';
import { MaterialCustomModule }      from '@Material/';
import { MobileCellInfoComponent }   from '@ObjElements/mobile-cells/mobile-cell/mobile-cell-info.component';

import { DeviceMobilePhone }         from './device-mobilephone.class';
import { DeviceInfoComponent }       from '../device/';
import {
    ConnectionInfoComponent,
    PowerInfoComponent
}                                    from '../attributes';


// import { MobileCellCommon as MobileCell }  from '../../mobile-cells/'; // [TBD]


@Component({
    selector:    'qp-device-mobilephone-info',
    templateUrl: 'device-mobilephone-info.component.html',
    styleUrls:   [
        'device-mobilephone-info.component.css'
    ],
    imports:     [
        // ConnectionInfoComponent,
        // PowerInfoComponent,
        MobileCellInfoComponent,
        // TableDataComponent,

        DeviceInfoComponent,

        CommonModule,
        MaterialCustomModule
    ]
})
export class DeviceMobilePhoneInfoComponent extends ElementInfoComponent
{
    constructor(                ActRoute: ActivatedRoute,
                                Router:   Router,
                
                public readonly Media:    MediaObserver)
    {
        super(ActRoute, Router);
    }


    //
    // Getters
    //
    public get d(): DeviceMobilePhone
    {
        return this.data as DeviceMobilePhone;
    }

    
    public get mobilecellsArray(): any[]
    {
        return (this.d instanceof DeviceMobilePhone && Array.isArray(this.d.mobileCells))
            ? this.d.mobileCells.sort((a, b) => a.simslot - b.simslot)
            : [];
    }
}