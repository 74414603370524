<mat-dialog-content>
	<ng-template #container></ng-template>
</mat-dialog-content>


<mat-dialog-actions>
    @if (! disableCloseButton) {
        <button mat-raised-button mat-dialog-close color="primary"
            [matTooltip]="closeButtonText ? closeButtonText : AppStrings.Common_Close"

            (click)="close()"
        >
            {{ closeButtonText ? closeButtonText : AppStrings.Common_Close }}
        </button>
    }

    @if (enableOkButton) {
        <!-- <button mat-raised-button mat-dialog-close color="primary" -->
        <button mat-raised-button color="primary"
            [matTooltip]="okButtonText ? okButtonText : AppStrings.Common_Ok"

            (click)="ok()"
        >
            {{ okButtonText ? okButtonText : AppStrings.Common_Ok }}
        </button>
    }
</mat-dialog-actions>