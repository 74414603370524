@if (d) {
    <qp-table-data
        [nullValues]="nullValues"
        [data]="
            [
                { k: 'Pci',           v: d.pci                               },
                { k: 'Tac',           v: d.tac                               }
            ]"
    >
    </qp-table-data>

    <qp-mobilecell-signalstrength
        [d]="d.signalstrength" [cat]="d.signalstrengthcategory"
    >
    </qp-mobilecell-signalstrength>

    <qp-mobilecell-signalquality
        [d]="d.signalquality" [cat]="d.signalqualitycategory"
    >
</qp-mobilecell-signalquality>

    <qp-table-data
        [nullValues]="nullValues"
        [data]="
            [
                { k: 'Sinr Ss',       v: d.sinrss  ? d.sinrss  + ' dB' : ''  }
            ]"
    >
    </qp-table-data>

    <qp-mobilecell-signalstrength
        [d]="d.rsrpcsi"
    >
    </qp-mobilecell-signalstrength>

    <qp-mobilecell-signalquality
        [d]="d.rsrqcsi"
    >
    </qp-mobilecell-signalquality>

    <qp-table-data
        [nullValues]="nullValues"
        [data]="
            [
                { k: 'Sinr Csi',      v: d.sinrcsi ? d.sinrcsi + ' dB' : ''  },
                { k: 'Asu',           v: d.asu                               },
                { k: 'Cqi',           v: d.cqi                               },
                { k: 'Ta',            v: d.ta                                }
            ]"
    >
    </qp-table-data>
    <!-- { k: 'Local cell Id', v: d.localcellid                       }, -->
}