@if (d) {
    <qp-table-data
        [nullValues]="nullValues"
        [data]="
            [
                (d.rttAverage !== undefined)
                    ? { k: AppStrings.Test_RttAverage, v: d.rttAverage }
                    : undefined,
            ]">
    </qp-table-data>
}