import { Injectable }        from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    Subject,
    Subscription,

    take
}                             from 'rxjs';

import { BaseService }        from '@Base/';
import {
    Messages,
    MessageProcessingService,
    MessageService
}                             from '@Messaging/';


export enum DataServiceEvents {
    added   = "added",
    data    = "data",
    deleted = "deleted",
    loading = "loading",
    number  = "number",
    status  = "status",
    updated = "updated"
} // DataServiceEvents


export interface DataServiceI
{
    [DataServiceEvents.added  ]: Observable<string>            | undefined,       // event sent when element is added to service
    [DataServiceEvents.data   ]: Observable<object[]>          | undefined,       // event gives all elements currently present in service
    [DataServiceEvents.deleted]: Observable<string>            | undefined,       // event sent when element is deleted from service
    [DataServiceEvents.loading]: Observable<boolean>           | undefined,       // event sent when service requests/receives data from server
    [DataServiceEvents.number ]: Observable<number>            | undefined,       // event gives number of elements currently present in service
    [DataServiceEvents.status ]: Observable<string[] | object> | undefined,       // event gives all element IDs currently present in service
    [DataServiceEvents.updated]: Observable<string>            | undefined,       // event sent when element is updated in service
    
    get(id: string | number, type? : any):      object | undefined                // get element from service
    getL(id: string | number, type? : any):     object | Subscription | undefined // get element from service, look-up on server if not present
    getAll(type?: any):                         object[] | undefined              // get all elements from service
    getObservable(type: string, event: string):
        Observable<boolean | number | object | object[] | string | string[]> | undefined;  // get one of the above event observers
    refresh(full? : boolean): void                                                // trigger service refresh
    refresh2(startIndex?: number, quantity?: number): void                        // trigger service refresh
    refresh3(filter: string, startIndex?: number, quantity?: number): void        // trigger service refresh
} // DataServiceI


export abstract class DataService extends BaseService implements DataServiceI
{
    private   static readonly spinnerTimeout:   number                     = 500; // ms

    protected        readonly _data:            { [key: string]: any }     = {};
    private                   _msgType:         string                     = "";
    private                   _size:            number                     = 0;

    protected                 _dataElements$:   BehaviorSubject<object[]>;
    protected                 _dataLoading$:    BehaviorSubject<boolean>;
    protected                 _elementAdded$:   Subject<string>;
    protected                 _elementDeleted$: Subject<string>;
    protected                 _elementUpdated$: Subject<string>;
    protected                 _elementsNum$:    BehaviorSubject<number>;
    protected                 _status$:         BehaviorSubject<string[]>;


    constructor(protected readonly MessageService: MessageService,
                protected readonly Title:          string,
                                   msgT?:          string,
                                   eventT?:        Observable<any> | undefined)
    {
        super();

        if (msgT) this.msgType = msgT;

        this._dataElements$   = new BehaviorSubject<object[]>([]);
        this._dataLoading$    = new BehaviorSubject<boolean>(false);
        this._elementAdded$   = new Subject<string>();
        this._elementDeleted$ = new Subject<string>();
        this._elementUpdated$ = new Subject<string>();
        this._elementsNum$    = new BehaviorSubject<number>(0);
        this._status$         = new BehaviorSubject<string[]>([]);

        // Listen for events
        if (eventT) {
            // const obs: Observable<any> | undefined = this.MessageProcessingService.getObs$(this._eventType = eventT);
            if (eventT instanceof Observable) this.sub = eventT
                .subscribe((d: any): void => {
                    this.processMsgData(d);
                    // if (this.process(d, false)) this.sizeI = this.size + 1;
                    this.updateObservables(false);
                }); // subscribe
        }
    }


    //
    // Interface
    //
    public abstract get(id: string | number, type? : any): object | undefined


    // Override
    public get [DataServiceEvents.added](): Observable<string> | undefined
    {
        return this.elementAdded$ instanceof Subject ? this.elementAdded$.asObservable() : undefined;
    }

    // Override
    public get [DataServiceEvents.data](): Observable<object[]> | undefined
    {
        // If size but no elements, trigger full data re-load
        // if (this.size > this.sizeI && this.msgType) this.refresh(true);

        return this.dataElements$ instanceof Subject ? this.dataElements$.asObservable() : undefined;
    }

    // Override
    public get [DataServiceEvents.deleted](): Observable<string> | undefined
    {
        return this.elementDeleted$ instanceof Subject ? this.elementDeleted$.asObservable() : undefined;
    }

    // Override
    public get [DataServiceEvents.loading](): Observable<boolean> | undefined
    {
        return this.dataLoading$ instanceof Subject ? this.dataLoading$.asObservable() : undefined;
    }

    // Override
    public get [DataServiceEvents.number](): Observable<number> | undefined
    {
        return this.elementsNum$ instanceof Subject ? this.elementsNum$.asObservable() : undefined;
    }

    // Override
    public get [DataServiceEvents.status](): Observable<string[]> | undefined
    {
        return this.status$ instanceof Subject ? this.status$.asObservable() : undefined;
    }

    // Override
    public get [DataServiceEvents.updated](): Observable<string> | undefined
    {
        return this.elementUpdated$ instanceof Subject ? this.elementUpdated$.asObservable() : undefined;
    }


    //
    // Getters and setters
    //
    protected get dataE(): any[]
    {
        return Object.values(this.dataI);
    }


    protected get dataI(): { [key: string]: any }
    {
        return this._data;
    }


    protected get dataK(): any[]
    {
        return Object.keys(this.dataI);
    }


    private get msgType(): string
    {
        return this._msgType;
    }

    private set msgType(d: string)
    {
        this._msgType = d;
    }


    public get size(): number
    {
        return this._size;
    }

    protected get sizeI(): number
    {
        return this.dataK.length;
    }

    protected set sizeI(d: number)
    {
        this._size = d;
        if (this.elementsNum$ instanceof Subject) this.elementsNum$.next(this._size);
    }


    protected get dataElements$(): BehaviorSubject<object[]>
    {
        return this._dataElements$;
    }


    protected get dataLoading$(): BehaviorSubject<boolean>
    {
        return this._dataLoading$;
    }


    protected get elementAdded$(): Subject<string> 
    {
        return this._elementAdded$;
    }


    protected get elementDeleted$(): Subject<string> 
    {
        return this._elementDeleted$;
    }


    protected get elementUpdated$(): Subject<string> 
    {
        return this._elementUpdated$;
    }


    protected get elementsNum$(): BehaviorSubject<number>
    {
        return this._elementsNum$;
    }


    protected get status$(): BehaviorSubject<string[]>
    {
        return this._status$;
    }


    //
    // Public methods
    //

    // Override
    public getL(id: string | number, type? : any): object | Subscription | undefined
    {
        const o: object | undefined = this.get(id, type);
        if (o) return o;
        else {
            // Do lookup
            console.debug("Looking up " + this.Title + " service: id " + id + ", type = " + type);

            const obs: Observable<any> | undefined = this.MessageService.sendMsgGet(
                this.msgType,
                {
                    [Messages.msgTypesAttributes.id]:      id,
                    [Messages.msgTypesAttributes.summary]: false//true
                }
            ); // sendMsgGet

            return (obs instanceof Observable) 
                ? (this.sub = obs
                    .pipe(
                        take(1)
                    ) // only listen once

                    .subscribe({
                        // Message
                        next: (msg: any): void => {
                            this.processMsg(msg);
                        }, // next

                        // Error
                        error: (err: object): void => {
                            console.log(this.Title + " msg error (2)");
                            console.log(err);
                        }, // error

                            // Complete
                        complete: (): void => {
                        } // complete
                    }) //subscribe()
                )
                
                : undefined;
        }
    }


    // Override
    public getAll(type?: any): object[] | undefined
    {
        return this.dataE;
    }


    // Override
    public getObservable(type: string, event: string): Observable<boolean | number | object[] | string | string[]> | undefined
    {
        switch (event) {
            case DataServiceEvents.added:   return this[DataServiceEvents.added];
            case DataServiceEvents.data:    return this[DataServiceEvents.data];
            case DataServiceEvents.deleted: return this[DataServiceEvents.deleted];
            case DataServiceEvents.loading: return this[DataServiceEvents.loading];
            case DataServiceEvents.number:  return this[DataServiceEvents.number];
            case DataServiceEvents.status:  return this[DataServiceEvents.status];
            case DataServiceEvents.updated: return this[DataServiceEvents.updated];

            default:
                return undefined;
        } // switch
    }


    public refresh(full: boolean = false): void
    {
        this._refresh(full);
    }

    public refresh2(startIndex?: number, quantity?: number): void
    {
        this._refresh(true, startIndex, quantity);
    }

    public refresh3(filter: string, startIndex?: number, quantity?: number): void
    {
        this._refreshSearch(filter, startIndex, quantity);
    }


    //
    // Protected methods
    //
    protected add(k: any, v: any, update: boolean = true): any
    {
        const isNew: boolean = !! (k !== undefined && ! (k in this.dataI));
        this.dataI[k] = v;
        
        // Do conditionally as can be slow with repeated calls to add()
        if (update) {
            if (this.elementAdded$ instanceof Subject) this.elementAdded$.next(k);
            this.updateObservables(true, update);
        }

        return isNew ? this.dataI[k] : undefined;
    }


    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();

        if (this.dataElements$   instanceof Subject) this.dataElements$.complete();
        if (this.dataLoading$    instanceof Subject) this.dataLoading$.complete();
        if (this.elementAdded$   instanceof Subject) this.elementAdded$.complete();
        if (this.elementDeleted$ instanceof Subject) this.elementDeleted$.complete();
        if (this.elementUpdated$ instanceof Subject) this.elementUpdated$.complete();
        if (this.elementsNum$    instanceof Subject) this.elementsNum$.complete();
        if (this.status$         instanceof Subject) this.status$.complete();

        this.clear();
    }


    protected clear(update: boolean = false): void
    {
        this.dataK.forEach((d: string): void => {
            this.del(d, update);
        }); // forEach
        if (! update) this.updateObservables(false, update);
    }


    protected del(k: any, update: boolean = true): any
    {
        if (this.dataI.hasOwnProperty(k)) {
            delete this.dataI[k]

            // Do conditionally as can be slow with repeated calls to del()
            if (update) {
                if (this.elementDeleted$ instanceof Subject) this.elementDeleted$.next(k);
                this.updateObservables(true, update);
            }
            
            return k;
        }
        
        return undefined;
    }

    
    protected getElement(id: string): object | undefined
    {
        return id ? this.dataI[id] : undefined
    }


    // Override
    protected override initialise(): void
    {
        super.initialise();

        if (this.dataLoading$ instanceof Subject) this.dataLoading$.next(true); // mark as loading until first data message is received
    }


    // Allow to be overridden by children
    protected processMsgData(d: any[]): void
    {
        // Do nothing here
    }


    // Allow to be overridden by children
    protected processMsg(d: any): void
    {
        // Check for quantityOnly
        if (d && Messages.msgTypesAttributes.data in d) {
            const data = d[Messages.msgTypesAttributes.data];
            if (Messages.msgTypesAttributes.quantity in data) {
                this.clear();
                this.sizeI = data[Messages.msgTypesAttributes.quantity];
            }

            if (data && Array.isArray(data)) this.processMsgData(data)
            else                             this.updateObservables(false, true); // essential for 'quantity' processing
        }
    }


    protected updateObservables(updateSize: boolean = true, status: boolean = true): void
    {
        if (updateSize) this.sizeI = this.sizeI;//this.elementsNum$.next(this.size);

        if (this.status$ instanceof Subject) this.status$.next(this.dataK);
        if (status && this.dataElements$ instanceof Subject) this.dataElements$.next(this.dataE);
    }


    //
    // Private methods
    //
    private _refresh(full: boolean = false, startIndex?: number, quantity?: number): void
    {
        // if (msgType) this.msgType = msgType;

        if (! this.msgType) {
            console.warn("Cannot refresh " + this.Title + " service; msg type is not set (1): " + this.msgType);
            return;
        }

        this.dataLoading$.next(true);
        console.debug("Refreshing " + this.Title + " service: full = " + full + ", start = " + startIndex + ", quantity = " + quantity + ", current size =  " + this.size);

        return this._refreshProcess(
            this.MessageService.sendMsgGet(
                this.msgType,
                {
                    [Messages.msgTypesAttributes.quantity]:   ! full,
                    [Messages.msgTypesAttributes.limitstart]: startIndex,
                    [Messages.msgTypesAttributes.limitend]:   quantity
                }
            ) // sendMsgGet
        ); // _refreshProcess
    }


    private _refreshSearch(filter: string, startIndex?: number, quantity?: number): void
    {
        // if (msgType) this.msgType = msgType;

        if (! this.msgType) {
            console.warn("Cannot refresh " + this.Title + " service; msg type is not set (2): " + this.msgType);
            return;
        }

        this.dataLoading$.next(true);
        console.debug("Refreshing " + this.Title + " service: filter = " + filter + ", start = " + startIndex + ", quantity = " + quantity + ", current size =  " + this.size);

        return this._refreshProcess(
            this.MessageService.sendMsgGet(
                this.msgType,
                {
                    [Messages.msgTypesAttributes.filter]:     filter,
                    [Messages.msgTypesAttributes.limitstart]: startIndex,
                    [Messages.msgTypesAttributes.limitend]:   quantity
                }
            ) // sendMsgGet
        ); // _refreshProcess
    }


    private _refreshProcess(obs: Observable<any> | undefined)
    {
        if (obs instanceof Observable) this.sub = obs
            .pipe(
                take(1)
            ) // only listen once

            .subscribe({
                // Message
                next: (msg: any): void => {
                    this.processMsg(msg);
                    if (this.dataLoading$ instanceof Subject) this.dataLoading$.next(false);
                }, // next

                // Error
                error: (err: object): void => {
                    console.log(this.Title + " msg error");
                    console.log(err);
                    if (this.dataLoading$ instanceof Subject) this.dataLoading$.next(false);
                }, // error

                    // Complete
                complete: (): void => {
                    if (this.dataLoading$ instanceof Subject) this.dataLoading$.next(false);
                } // complete
            }); //subscribe()
    }
}