import { CommonModule }                     from '@angular/common';
import {
    Component,
    Input
}                                            from '@angular/core';

import { ElementInfoComponent }              from '../../element/element/element-info.component';
// import { MobileCellLevelIconComponent }      from '@GuiElements/mobilecell-level-icon.component';
import { MobileCellTechnologyIconComponent } from '@GuiElements/mobilecell-technology-icon.component';
import { TableDataComponent }                from '@GuiElements/table-data.component';

import { MaterialCustomModule }              from '@Material/';
import { MobileCellLevelIconComponent }      from '@MobileCells/MobileCell/elements/mobilecell-level-icon.component';


import {
    MobileCell,
    MobileCellType
    // MobileTechnology
}                                            from './';
import { MobileCellCdmaInfoComponent }       from '../mobile-cell-cdma/mobile-cell-cdma-info.component';
import { MobileCellGsmInfoComponent }        from '../mobile-cell-gsm/mobile-cell-gsm-info.component';
import { MobileCellLteInfoComponent }        from '../mobile-cell-lte/mobile-cell-lte-info.component';
import { MobileCellNrInfoComponent }         from '../mobile-cell-nr/mobile-cell-nr-info.component';
import { MobileCellNrnsaInfoComponent }      from '../mobile-cell-nrnsa/mobile-cell-nrnsa-info.component';
import { MobileCellTdscdmaInfoComponent }    from '../mobile-cell-tdscdma/mobile-cell-tdscdma-info.component';
import { MobileCellUmtsInfoComponent }       from '../mobile-cell-umts/mobile-cell-umts-info.component';


@Component({
    selector:    'qp-mobile-cell-info',
    templateUrl: 'mobile-cell-info.component.html',
    styleUrls:   [
        'mobile-cell-info.component.css'
    ],
    imports:     [
        MobileCellLevelIconComponent,
        MobileCellTechnologyIconComponent,
        TableDataComponent,

        MobileCellCdmaInfoComponent,
        MobileCellGsmInfoComponent,
        MobileCellLteInfoComponent,
        MobileCellNrInfoComponent,
        MobileCellNrnsaInfoComponent,
        MobileCellTdscdmaInfoComponent,
        MobileCellUmtsInfoComponent,

        CommonModule,
        MaterialCustomModule
        
    ]
})
export class MobileCellInfoComponent extends ElementInfoComponent
{

    @Input()
    public cell:                     any;


    @Input()
    public nullValues:               boolean = true;
    

    @Input()
    public showMobileCellLevel:      boolean = true;


    @Input()
    public showMobileCellSim:        boolean = true;


    @Input()
    public showMobileCellTechnology: boolean = true;


    @Input()
    public useExpansion:             boolean = false;


    //
    // Getters
    //
    get d(): any
    {
        return this.cell;
    }

    
    get mobileTechnology(): typeof MobileCellType//MobileCell.Technology
    {
        return MobileCellType;
        // return MobileCell.Technology;
    }


    get technology(): string
    {
        return (this.d instanceof MobileCell && this.d.technology instanceof MobileCellType)
            ? this.d.technology.type
            : "";
    }


    get technologyDetail(): string
    {
        return (this.d instanceof MobileCell)
            ? this.d.technologydetail
            : "";
    }
}