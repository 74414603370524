import {
    Component,
    EventEmitter,
    Input,
    Output
}                                       from '@angular/core';

import { BaseComponent }                from '@Base/';
import {
    TableDataComponent,
    TableDataValue
}                                       from '@GuiElements/table-data.component';
import { MaterialCustomModule }         from '@Material/';

import { MobileCellSignalCategories }   from '@Common/Elements/MobileCell/';

import { MobileCellLevelIconComponent } from './mobilecell-level-icon.component';


@Component({
    selector:    'qp-mobilecell-signalquality',
    templateUrl: 'mobilecellsignalquality.component.html',
    styleUrls:   [
        'mobilecellsignalquality.component.css',
        'mobilecell-signal-categories.css'
    ],
    imports:     [
        TableDataComponent,
        
        MaterialCustomModule
    ]
})
export class MobileCellSignalQualityComponent extends BaseComponent
{
    @Input()
    public d: any;


    @Input()
    public cat: MobileCellSignalCategories;


    @Output()
    public readonly selected: EventEmitter<TableDataValue> = new EventEmitter();


    //
    // Getters
    //
    public get class_(): string 
    {
        return MobileCellLevelIconComponent.getState(this.cat);
    }

    
    //
    // Public methods
    //
    public select(d: TableDataValue)
    {
        this.selected.emit(d);
    }
}